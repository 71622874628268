@import "colors";

$font-family-base: Montserrat;
$headings-font-family: Montserrat;

$enable-gradients: true;
$enable-shadows: true;

$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);

$border-radius: .4rem;

@import "bootstrap/scss/bootstrap";

@each $k, $v in $grays {
  .text-gray-#{$k} {
    color: $v !important;
  }

  .bg-gray-#{$k} {
    background-color: $v !important;
  }
}

.my-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: $overlay-dark;
  background: linear-gradient(95deg, $overlay-dark 40%, $overlay-light 100%);
  transform: skewY(-12deg);
  transform-origin: 0;
  z-index: -12;
}

.my-navbar {
  @include transition(0.3s);

  .container:has(.collapse:not(.show)) {
    flex-wrap: nowrap;
  }

  .container:has(.collapse.show), .container:has(.collapsing) {
    #title {
      display: none !important;
    }
  }

  .navbar-brand {
    img {
      width: 24px;
      height: 24px;
      object-fit: contain;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 12px 0;

    .nav-link {
      position: relative;
      font-weight: 500;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 2px;
        background-color: $greengage-color;
        opacity: 0;
      }

      &.active {
        &::after {
          width: 100%;
          transition: width 500ms ease, opacity 500ms ease;
          opacity: 1;
        }
      }
    }

    .navbar-brand {
      img {
        width: 32px;
        height: 36px;
        object-fit: contain;
      }
    }

    a.current-lang {
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 2px;
        background-color: $greengage-color;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    .navbar-collapse {
      padding: 1rem;
      border: 1px solid $overlay-dark;
      border-radius: $dropdown-border-radius;
      box-shadow: $box-shadow;
      background: $overlay-dark;
      background: linear-gradient(95deg, $overlay-dark 40%, $overlay-light 100%) !important;
    }
  }
}

.menu-bg {
  background: $overlay-dark;
  background: linear-gradient(95deg, $overlay-dark 40%, $overlay-light 100%) !important;
  box-shadow: 0 0 7px 1px rgba(0, 0, 0, .1);
  transition: all .5s ease-in-out;
}

p {
  font-weight: 300;
}

.lead {
  font-weight: 400;
}

.my-title {
  font-weight: bold;
  line-height: 1;
  @include font-size(3.75rem);
}

.my-section {
  padding-top: 3rem;
  padding-bottom: 3rem;

  &.first {
    padding-top: 5rem;
  }

  @include media-breakpoint-up(lg) {
    &:not(.last) {
      padding-top: 6rem;
      padding-bottom: 6rem;

      &.first {
        padding-top: 7rem;
      }
    }
  }
}

.text-greengage {
  color: $greengage-color;
}

.text-linkedin {
  color: $linkedin-color;
}

.text-gitlab {
  color: $gitlab-color;
}

.highlight-greengage {
  color: $white;
  background-color: $greengage-color;
}

.my-greengage-icon {
  width: 32px;
  height: 36px;
  object-fit: contain;
}

.my-team-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.my-eco-image {
  width: 120px;
  height: 60px;
  object-fit: contain;
}

.my-showcase-image {
  width: 240px;
  height: 50px;
  object-fit: contain;

  @include media-breakpoint-up(lg) {
    &.my-small-lg-image {
      width: 120px;
      height: 25px;
      object-fit: contain;
    }
  }
}

.my-check {
  color: $greengage-color;
}

.my-external-link {
  font-weight: bold;
}

.my-how-number {
  min-width: 40px;
  font-weight: 300;
  @include font-size(5rem);
  line-height: 1;
  color: $greengage-color;

  @include media-breakpoint-up(lg) {
    min-width: 70px;
  }
}

.my-how-background {
  opacity: .075;

  > i {
    font-size: 10em;

    @include media-breakpoint-up(lg) {
      font-size: 12.5em;
    }
  }
}

.my-how-bg-color {
  > div {
    @include media-breakpoint-down(lg) {
      &:nth-child(2), &:nth-child(4) {
        background-color: $blue-0;
      }
    }

    @include media-breakpoint-up(lg) {
      &:nth-child(2), &:nth-child(3) {
        background-color: $blue-0;
      }
    }
  }
}

#eco {
  background-color: $eco-bg;
}

.my-eco-grid {
  background-color: $white;

  > div {
    &:nth-child(1) {
      border-bottom: $border-width solid $border-color;

      @include media-breakpoint-up(lg) {
        border-right: $border-width solid $border-color;
      }
    }

    &:nth-child(2) {
      border-bottom: $border-width solid $border-color;
    }

    &:nth-child(3) {
      border-bottom: $border-width solid $border-color;

      @include media-breakpoint-up(lg) {
        border-right: $border-width solid $border-color;
        border-bottom: none;
      }
    }
  }
}

#showcase {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 400px;
    top: 0;
    left: 0;
    background: $overlay-dark;
    background: linear-gradient(95deg, $overlay-dark 40%, $overlay-light 100%);
    // transform: skewY(-12deg);
    // transform-origin: 0;
    z-index: -12;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 400px;
    top: 0;
    left: 0;
    background: #00000000;
    background: linear-gradient(0deg, $body-bg 20%, #00000000 100%);
    // transform: skewY(-12deg);
    // transform-origin: 0;
    z-index: -11;
  }
}

.my-field-mandatory:after {
  content: '*';
  margin-left: 0.4375rem;
  color: $danger;
}
