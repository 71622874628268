$gray-0: #f9f9f9;
$gray-1: #eceeed;
$gray-2: #dee1e0;
$gray-3: #cfd4d2;
$gray-4: #bfc5c3;
$gray-5: #adb5b2;
$gray-6: #98a29e;
$gray-7: #808c87;
$gray-8: #616f6a;
$gray-9: #34423d;

$teal-0: #f7faf9;
$teal-1: #e6f0ec;
$teal-2: #d5e4de;
$teal-3: #c1d8cf;
$teal-4: #accbbe;
$teal-5: #94bcab;
$teal-6: #78aa96;
$teal-7: #57957c;
$teal-8: #2a7959;
$teal-9: #124832;

$cyan-0: #f7fafa;
$cyan-1: #e7eff0;
$cyan-2: #d5e3e5;
$cyan-3: #c2d7d9;
$cyan-4: #adc9cc;
$cyan-5: #96b9bd;
$cyan-6: #7ba7ac;
$cyan-7: #5b9298;
$cyan-8: #30767d;
$cyan-9: #13464b;

$blue-0: #f8f9fb;
$blue-1: #ebeef2;
$blue-2: #dce1e9;
$blue-3: #ccd4df;
$blue-4: #bac5d4;
$blue-5: #a7b4c8;
$blue-6: #90a1ba;
$blue-7: #768aa9;
$blue-8: #546d93;
$blue-9: #1d3f71;

$indigo-0: #f9f9fb;
$indigo-1: #ededf4;
$indigo-2: #e1e0eb;
$indigo-3: #d3d1e2;
$indigo-4: #c4c1d8;
$indigo-5: #b3b0cd;
$indigo-6: #9f9cc1;
$indigo-7: #8884b2;
$indigo-8: #6b659f;
$indigo-9: #3c3480;

$violet-0: #faf9fb;
$violet-1: #f0ecf3;
$violet-2: #e5deea;
$violet-3: #dacfe1;
$violet-4: #cdbfd7;
$violet-5: #bfaccb;
$violet-6: #ae97be;
$violet-7: #9b7eae;
$violet-8: #815e9a;
$violet-9: #582978;

$fuschia-0: #fbf9fb;
$fuschia-1: #f3ebf2;
$fuschia-2: #eadde8;
$fuschia-3: #e0cdde;
$fuschia-4: #d5bcd3;
$fuschia-5: #c9a9c6;
$fuschia-6: #bb93b8;
$fuschia-7: #ab79a6;
$fuschia-8: #95568f;
$fuschia-9: #6d1c65;

$pink-0: #fbf9fa;
$pink-1: #f3ecef;
$pink-2: #eadee3;
$pink-3: #e0ced6;
$pink-4: #d6bdc7;
$pink-5: #caabb7;
$pink-6: #bc95a5;
$pink-7: #ac7b8f;
$pink-8: #975972;
$pink-9: #731f41;

$red-0: #fbf9f9;
$red-1: #f3eceb;
$red-2: #eadedd;
$red-3: #e0cfcd;
$red-4: #d5bfbc;
$red-5: #c9aca9;
$red-6: #bb9794;
$red-7: #ab7e79;
$red-8: #965d57;
$red-9: #71261e;

$orange-0: #faf9f8;
$orange-1: #f0ede8;
$orange-2: #e6e0d7;
$orange-3: #dbd2c5;
$orange-4: #cec2b1;
$orange-5: #c0b19b;
$orange-6: #b09d81;
$orange-7: #9d8663;
$orange-8: #84673c;
$orange-9: #543a15;

$yellow-0: #f9faf6;
$yellow-1: #edeee4;
$yellow-2: #e1e2d1;
$yellow-3: #d3d5bc;
$yellow-4: #c3c7a5;
$yellow-5: #b2b68b;
$yellow-6: #9fa46e;
$yellow-7: #878e4b;
$yellow-8: #69711e;
$yellow-9: #3e4211;

$lime-0: #f8faf7;
$lime-1: #eaefe6;
$lime-2: #dae4d4;
$lime-3: #c9d7c0;
$lime-4: #b7caaa;
$lime-5: #a2ba92;
$lime-6: #8aa976;
$lime-7: #6e9454;
$lime-8: #497828;
$lime-9: #284712;

$green-0: #f7faf7;
$green-1: #e7f0e8;
$green-2: #d5e5d7;
$green-3: #c2d9c4;
$green-4: #adccb0;
$green-5: #95bd99;
$green-6: #7aac7f;
$green-7: #59975f;
$green-8: #2d7b34;
$green-9: #134918;

$greengage-color: $teal-8;
$linkedin-color: #0a66c2;
$gitlab-color: #FC6D26;

$overlay-dark: $blue-4;
$overlay-light: $teal-1;
$eco-bg: $blue-2;

$body-color: #31393d;
$body-bg: #fefefe;
$success: $fuschia-7;
$danger: $red-8;
$primary: $greengage-color;